<template>
    <Navbar :user="user"/>
    <section class="courses-details-area pb-70" v-if="!isLoading">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-10">
            <div class="shadow-lg pt-5">
              <div class="courses-details-desc text-start">
                  <h2 class="text-center">{{ test.title }}</h2>
                  <h4 class="text-center">Test Summary</h4>
                  <div class="row">
                    <div class="col-md-4 offset-md-4">
                      <table class="table table-hover table-sm">
                        <tbody>
                          <tr>
                            <td>Your Score:</td>
                            <td>{{ test_results.score }}</td>
                          </tr>
                          <tr>
                            <th>Total Score:</th>
                            <th>{{ test_results.total_question }}</th>
                          </tr>
                          <tr>
                            <td>Summary:</td>
                            <td>
                              <span v-if="(test_results.score / test_results.total_question ) * 100 >= 50" class="text-success">
                                Passed
                              </span>
                              <span v-else class="text-danger">
                                Failed
                              </span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <div class="text-center">
                        <router-link :to="{ name: 'Home' }" class="btn default-btn">
                          <span>
                            Home
                          </span>
                        </router-link>
                      </div>
                    </div>
                  </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </section>
    <div v-else>
      <Loading />
    </div>
</template>

<script>
import Navbar from '@/components/Navbar.vue'
import Loading from '@/components/Loading.vue'
import axios from 'axios'
import moment from 'moment'
export default {
 components: { Navbar, Loading },
  data(){
    return {
      isLoading: true,
      subscriptionStatus: 0,
      rating: 4.3,
      test: {},
      user:{},
      test_results:{},
      question: {},
      token: localStorage.getItem('user_data'),
      temp_id: localStorage.getItem('temp_id'),
    }
  },
  methods:{
    getTest(){
      let id = this.$route.params.id
      axios.get('https://apitraining.vipawaworks.com/api/self_test/get_test/' + id).then(response => {
        this.test = response.data
        document.title = this.test.title +' - Training'
      }).catch(errors => {
        this.errors = errors.response.data.errors
      })
    },
    getTestResults(){
      let id = this.$route.params.id
      axios.get('https://apitraining.vipawaworks.com/api/self_test/get_test_result/' + id +'/' + this.temp_id).then(response => {
        this.test_results = response.data
      }).catch(errors => {
        this.errors = errors.response.data.errors
      }).finally(() => {
        setTimeout(function () {
          this.isLoading =  false
        }.bind(this), 500);
      })
    },
    
  },
  created(){
    axios.defaults.headers.common['Authorization'] = `Bearer ${this.token}` 
    this.getTest()
    this.getTestResults()
    this.moment = moment
  }
}
</script>

<style>

</style>